/* open sans self hosted styles */
@font-face {
  font-family: "opensans-light";
  src: url("/assets/fonts/opensans/opensans-light-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/opensans/opensans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "opensans-regular";
  src: url("/assets/fonts/opensans/opensans-regular-webfont.woff2")
      format("woff2"),
    url("/assets/fonts/opensans/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "opensans-bold";
  src: url("/assets/fonts/opensans/opensans-bold-webfont.woff2") format("woff2"),
    url("/assets/fonts/opensans/opensans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
