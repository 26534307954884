$pf-primary-color: #1e204e;
$pf-secondary-color: #484a78;
$pf-tertiary-color: #797b9e;
$pf-info-color-yelow: #e28a18;
$pf-info-color: #8b8c9f;
$pf-success-color: green;
$pf-error-color: red;
$pf-layout-body-background: #f5f6fa;
$pf-font-family: Helvetica Neue Helvetica Arial sans-serif;
$pf-text-color: #6a6a71;
$pf-text-common-color: #3f3f42;
$pf-text-color-secondary: #d8d8e0;
$pf-border-color: #eaeaf4;
$pf-status-new-background: #d6e5fc;
$pf-status-new-text: #5577b9;
$pf-status-active-background: #d7fae0;
$pf-status-active-text: #09ab4f;
$pf-status-rejected-background: #ffd1d5;
$pf-status-rejected-text: #bf3643;
$pf-status-prospect-background: #f3e1b6;
$pf-status-prospect-text: #98762c;
$pf-status-inactive-background: #d9d6e1;
$pf-status-inactive-text: #907ca8;
