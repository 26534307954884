@import "./fonts.css";
@import "./variable.scss";

html {
  // font-family: "opensans-regular", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

html,
body {
  height: auto;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #575757;
  background: $pf-layout-body-background;
}

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  p {
    width: 50vw;
    text-align: center;
  }
  i {
    color: white;
  }
}

.main-container {
  min-width: 1024px;
  max-width: 1280px;
  width: 100%;
  padding: 20px;
  margin: 50px auto;
  margin-bottom: 0;
}
